<template>
  <div>
    <div class="d-flex justify-content-between align-items-center pt-50 mb-2">
      <h2 class="font-weight-bolder m-0">
        <span @click="$router.go(-1)">
          <b-button
            variant="white"
            class="btn-icon rounded-circle mr-50 bg-white"
            size="sm"
          >
            <feather-icon icon="ChevronLeftIcon" />
          </b-button>
        </span>
        {{ $t("DetallesUsuario") }}
      </h2>
    </div>
    <b-row v-if="userPrepare">
      <b-col cols="12">
        <b-card>
          <b-row class="mb-3">
            <b-col
              xl="5"
              md="8"
              class="d-flex align-items-center"
            >
              <div class="pr-2">
                <b-avatar
                  size="6rem"
                  :src="user.avatar"
                />
              </div>
              <div>
                <h4 class="m-0 font-weight-bolder">
                  {{ user.name }} {{ user.surname }}
                </h4>
                <span
                  v-for="r in user.roles"
                  :key="r.display_name"
                >
                  {{ r.display_name }}
                </span>
              </div>
            </b-col>
          </b-row>
          <b-row class="position-relative">
            <b-col
              v-if="role === 'admin_empresa' && !currentClient"
              xl="3"
              md="6"
            >
              <p class="mb-50 title-style">
                {{ $t("CLIENTES") }}
              </p>
              <p class="font-weight-bolder">
                <span
                  v-for="(c, i) in user.clients"
                  :key="`client-${c.name}-${c.id}`"
                ><span v-if="i !== 0">, </span>{{ c.name }}</span>
              </p>
            </b-col>
            <b-col
                xl="3"
                md="6"
                v-if="user.username"
            >
              <p class="mb-50 title-style text-uppercase">
                {{ $t("NombreUsuario") }}
              </p>
              <p class="font-weight-bolder">
                <span>{{ user.username }}</span>
              </p>
            </b-col>
            <b-col
                xl="3"
                md="6"
                v-if="user.dni"
            >
              <p class="mb-50 title-style text-uppercase">
                {{ $t("Dni") }}
              </p>
              <p class="font-weight-bolder">
                <span>{{ user.dni }}</span>
              </p>
            </b-col>
            <b-col v-if="user.departments && user.departments.length > 0"
              xl="3"
              md="6"
            >
              <p class="mb-50 title-style">
                {{ $t("DEPARTAMENTOS") }}
              </p>
              <p class="font-weight-bolder">
                <span
                  v-for="(d, i) in user.departments"
                  :key="`department-${d.name}-${d.id}`"
                ><span v-if="i !== 0">, </span>{{ d.name }}</span>
              </p>
            </b-col>
            <b-col v-if="user.locations && user.locations.length > 0"
              xl="3"
              md="6"
              >
              <p class="mb-50 title-style text-uppercase">
                {{ $t("Localizaciones") }}
              </p>
              <p class="font-weight-bolder">

                <span
                    v-for="(d, i) in user.locations"
                    :key="`location-${d.name}-${d.id}`"
                ><span v-if="i !== 0">, </span>
                   <b-link
                       :to="{ name: 'editLocation', params: { id: d.id } }"
                   >{{ d.name }}
                   </b-link>
                </span>
              </p>
            </b-col>
            <b-col
              xl="3"
              md="6"
            >
              <p class="mb-50 title-style">
                {{ $t("EMAIL") }}
              </p>
              <p class="font-weight-bolder">
                {{ user.email }}
              </p>
            </b-col>
            <b-col
              xl="3"
              md="6"
            >
              <p class="mb-50 title-style">
                {{ $t("TELEFONO") }}
              </p>
              <p class="font-weight-bolder">
                {{ user.phone }}
              </p>
            </b-col>
            <b-col
              cols="12"
              class="text-right"
            >
              <b-link
                :to="{ name: 'editUser', params: { id: user.id } }"
                class="d-inline-block text-dark pt-2"
              >
                <span class="">{{ $t("Editar") }}</span>
                <span class="pl-1"><feather-icon icon="Edit2Icon" /></span>
              </b-link>
            </b-col>
          </b-row>
        </b-card>

        <b-tabs
          content-class="col-12 mt-1 mt-md-0"
          nav-wrapper-class="col-md-3 col-12"
          nav-class="nav-left"
        >
          <!-- general tab -->
          <b-tab active>
            <!-- title -->
            <template #title>
              <feather-icon
                icon="LayersIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold">
                {{ $t("ActivosCirculacion") }}</span>
            </template>

            <ListAssets :userprop="user.id" />
          </b-tab>
          <!--/ general tab -->

          <!-- change password tab -->
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon
                icon="ClipboardIcon"
                size="18"
                class="mr-50"
              />
              <span class="font-weight-bold"> {{ $t("Tareas") }}</span>
            </template>

            <ListTasks :user="user" />
          </b-tab>
          <!--/ change password tab -->
        </b-tabs>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import {
  BButton,
  BAvatar,
  BRow,
  BCol,
  BCard,
  BLink,
  BTabs,
  BTab,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import Ripple from 'vue-ripple-directive'
import ListAssets from '@/views/assets/ListTable'
import ListTasks from '@/views/tasks/ListTable'

export default {
  components: {
    BButton,
    BAvatar,
    BRow,
    BCol,
    BCard,
    BLink,
    ListAssets,
    ListTasks,
    BTabs,
    BTab,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      id: this.$route.params.id,
      companies: '',
      departments: [],
      files: [],
      profileFile: null,
      required,
      email,
      userPrepare: false,
      changePassword: false,
      collapseInfo: false,
    }
  },
  computed: {
    ...mapGetters({
      currentClient: 'clients/getCurrentClient',
      user: 'users/getUser',
      checkJourney: 'users/getCheckJourney',
      role: 'auth/getRole',
    }),
    canRestartJourney() {
      if (this.checkJourney.length && this.checkJourney[0].hour_fin !== null) {
        return true
      }
      return false
    },
  },
  methods: {
    ...mapActions({
      getUser: 'users/getUser',
    }),
    ...mapMutations({
      setSelectDepartments: 'departments/setSelectDepartments',
    }),
  },
  async created() {
    await this.getUser(this.$route.params.id)
    this.userPrepare = true
  },
}
</script>
